<template>
  <section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" v-if="loading">
            <div class="filterationBox py-5">
              <Loading />
            </div>
          </v-col>
          <v-col md="12" cols="12" v-if="!loading">
            <h2 class="mb-5">
              Booking Number : <strong>{{ booking.bookingNumber }}</strong>
            </h2>
            <div class="filterationBox">
              <v-row class="py-3">
                <v-col md="12">
                  <h3 class="mb-3">Booking Detail</h3>
                  <ul class="bookingDetail">
                    <li>
                      <strong>Fullname : </strong
                      ><span>{{ booking.fullName }}</span>
                    </li>
                    <li>
                      <strong>Address : </strong
                      ><span>{{ booking.address }}</span>
                    </li>
                    <li>
                      <strong>Nearest Landmark : </strong
                      ><span>{{ booking.nearestLandMark }}</span>
                    </li>
                    <li>
                      <strong>Phone Number : </strong
                      ><span>{{ booking.phoneNumber }}</span>
                    </li>
                    <li>
                      <strong>Role : </strong><span>{{ user.gender }}</span>
                    </li>
                    <li>
                      <strong>Email : </strong><span>{{ user.email }}</span>
                    </li>
                    <li>
                      <strong>Booking Date : </strong
                      ><span
                        >{{ booking.bookingDate | moment("MMMM Do YYYY") }}
                        {{ booking.bookingTime | moment("HH:MM a") }}</span
                      >
                    </li>
                    <li>
                      <strong>Booking Status : </strong
                      ><span class="chipBox">{{ booking.bookingStatus }}</span>
                    </li>
                    <li v-if="booking.images.length > 0">
                      <strong>Attachments : </strong>
                      <div
                        v-for="(data, index) in booking.images"
                        :key="index + 'booking'"
                      >
                        <img :src="data.image" alt="" />
                      </div>
                    </li>
                  </ul>
                </v-col>
                <v-col md="12">
                  <hr />
                </v-col>
                <v-col md="12">
                  <h3 class="mb-3">Product Detail</h3>
                  <ul class="bookingDetail">
                    <li>
                      <img :src="product.images[0].image" alt="" width="200" />
                    </li>
                    <li>
                      <strong>Product Name : </strong
                      ><span>{{ product.productName }}</span>
                    </li>
                    <li>
                      <strong>Product Price : </strong
                      ><span>Rs. {{ numberFormat(product.price) }}</span>
                    </li>
                  </ul>
                </v-col>
                <v-col>
                  <v-btn class="mr-5">Approve</v-btn>
                  <v-btn class="dangerBtn">Cancel</v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="filterationBox" v-if="loading">
              <Loading />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SingleBooking",
  data: () => ({
    limit: 10,
    page: 1,
    booking: undefined,
    product: undefined,
    user: undefined,
    loading: true,
    reasons: [],
    description: undefined,
    reason: undefined,
    popStatus: false,
    loadingBtn: false,
  }),
  components: {
    Loading,
  },
  methods: {
    ...mapActions(["getBooking"]),
    popAction() {
      this.popStatus = !this.popStatus;
    },
    async orderAction(action) {
      this.loadingBtn = true;
      let filter = { orderStatus: action, id: this.order._id };
      if (action == "Cancelled") {
        filter.reason = this.reason;
        filter.description = this.description;
      }
      await this.putOrder(filter);
      this.booking = this.allBooking.results;
      this.loadingBtn = false;
      this.popStatus = false;
    },
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-IN");
      return internationalNumberFormat.format(givenNumber);
    },
  },
  computed: {
    ...mapGetters(["allBooking"]),
  },
  async created() {
    await this.getBooking(this.$route.params.id);
    this.booking = this.allBooking.results;
    this.product = this.allBooking.product;
    this.user = this.allBooking.user;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.totalList {
  list-style: none;
  padding-left: 0;
}
.shadowBox {
  box-shadow: 0 0 5px #d0cece;
  padding: 15px 15px 10px;
  border-radius: 10px;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.productItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
  img {
    width: 120px;
    border: 1px solid #efefef;
    margin-right: 20px;
  }
  div {
    width: 90%;
  }
  h3 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.totalList {
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
}
.v-btn {
  min-height: 5 0px;
  width: 200px;
  color: #0878d4 !important;
  background: #fff !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}
.chipBox {
  display: inline-block;
  background: rgb(213, 211, 211);
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 30px;
}
.filterationBox {
  h2 {
    font-size: 20px !important;
    margin-bottom: 8px;
  }
  border: 1px solid #efefef;
  padding: 10px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 15px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
}
.bookingDetail {
  list-style: none;
  padding-left: 0;
  li {
    margin-bottom: 10px;
  }
}
.dangerBtn {
  border-color: red;
  background: red !important;
  color: #fff !important;
}
</style>